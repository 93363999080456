@font-face {
  font-family: "totac";
  src:url("/fonts/totac.eot");
  src:url("../fonts/totac.eot?#iefix") format("embedded-opentype"),
    url("../fonts/totac.woff") format("woff"),
    url("../fonts/totac.ttf") format("truetype"),
    url("../fonts/totac.svg#totac") format("svg");
  font-weight: normal;
  font-style: normal;
} 

[data-icon]:before {
  font-family: "totac" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "totac" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: middle;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-certif:before {
  content: "\61";
}
.icon-clock:before {
  content: "\62";
}
.icon-contact:before {
  content: "\63";
}
.icon-emblem:before {
  content: "\64";
}
.icon-facebook:before {
  content: "\65";
}
.icon-go:before {
  content: "\66";
}
.icon-key:before {
  content: "\67";
}
.icon-pass:before {
  content: "\68";
}
.icon-presentation:before {
  content: "\69";
}
.icon-reglages:before {
  content: "\6a";
}
.icon-secure:before {
  content: "\6b";
}
.icon-stats:before {
  content: "\6c";
}
.icon-test:before {
  content: "\6d";
}
.icon-training:before {
  content: "\6e";
}
.icon-zoom:before {
  content: "\6f";
}
