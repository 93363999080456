/*

    Zebra_DatePicker: a lightweight jQuery date picker plugin

    Twitter Bootstrap theme

    copyright (c) 2011 - 2014 Stefan Gabos
    http://stefangabos.ro/jquery/zebra-datepicker/

*/

.Zebra_DatePicker *,
.Zebra_DatePicker *:after,
.Zebra_DatePicker *:before  { -moz-box-sizing: content-box !important; -webkit-box-sizing: content-box !important; box-sizing: content-box !important }

.Zebra_DatePicker           { position: absolute; background: #FFF; border: 1px solid #999; z-index: 1200; padding: 5px; top: 0 }

.Zebra_DatePicker *         { margin: 0; padding: 0; color: #373737; background: transparent; border: none }
 
/* = GLOBALS
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker table                      { border-collapse: collapse; border-spacing: 0; width: auto; table-layout: auto; }

.Zebra_DatePicker td,
.Zebra_DatePicker th                         { text-align: center; padding: 5px 0 }

.Zebra_DatePicker td                         { cursor: pointer }

.Zebra_DatePicker .dp_daypicker,
.Zebra_DatePicker .dp_monthpicker,
.Zebra_DatePicker .dp_yearpicker             { margin-top: 3px }

.Zebra_DatePicker .dp_daypicker td,
.Zebra_DatePicker .dp_daypicker th,
.Zebra_DatePicker .dp_monthpicker td,
.Zebra_DatePicker .dp_yearpicker td         { width: 30px }

.Zebra_DatePicker,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker td.dp_selected,
.Zebra_DatePicker .dp_footer .dp_hover,
.Zebra_DatePicker td.dp_hover               { -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px }

/* = VISIBLE/HIDDEN STATES (USE TRANSITIONS FOR EFFECTS)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker.dp_visible               { visibility: visible; filter: alpha(opacity=100); -khtml-opacity: 1; -moz-opacity: 1; opacity: 1; transition: opacity 0.2s ease-in-out }
.Zebra_DatePicker.dp_hidden                { visibility: hidden; filter: alpha(opacity=0); -khtml-opacity: 0; -moz-opacity: 0; opacity: 0 }

/* = HEADER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_header td             { }

.Zebra_DatePicker .dp_header .dp_previous,
.Zebra_DatePicker .dp_header .dp_next       { width: 30px }

.Zebra_DatePicker .dp_header .dp_caption    { font-weight: bold }
.Zebra_DatePicker .dp_header .dp_hover      { background: #DEDEDE; color: #373737 }

/* = DATEPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_daypicker th              { font-weight: bold }
.Zebra_DatePicker td.dp_not_in_month            { color: #DEDEDE; cursor: default }
.Zebra_DatePicker td.dp_not_in_month_selectable { }
.Zebra_DatePicker td.dp_weekend                 { }
.Zebra_DatePicker td.dp_weekend_disabled        { color: #DEDEDE; cursor: default }
.Zebra_DatePicker td.dp_selected                { background: #039; color: #FFF !important }
.Zebra_DatePicker td.dp_week_number             { cursor: text; font-weight: bold }

/* = MONTHPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_monthpicker td    { width: 33% }

/* = YEARPICKER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_yearpicker td     { width: 33% }

/* = FOOTER
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker .dp_footer            { margin-top: 3px }

/* = SOME MORE GLOBALS (MUST BE LAST IN ORDER TO OVERWRITE PREVIOUS PROPERTIES)
----------------------------------------------------------------------------------------------------------------------*/
.Zebra_DatePicker td.dp_current             { color: #3A87AD }
.Zebra_DatePicker td.dp_disabled_current    { color: #3A87AD }
.Zebra_DatePicker td.dp_disabled            { color: #DEDEDE; cursor: default }
.Zebra_DatePicker td.dp_hover               { background: #DEDEDE }

/* = ICON
----------------------------------------------------------------------------------------------------------------------*/
button.Zebra_DatePicker_Icon                { display: block; position: absolute; width: 16px; height: 16px; background: url('../images/calendar.png') no-repeat left top; text-indent: -9000px; border: none; cursor: pointer; padding: 0; line-height: 0; vertical-align: top }
button.Zebra_DatePicker_Icon_Disabled       { background-image: url('../images/calendar-disabled.png') }

/* don't set vertical margins! */
button.Zebra_DatePicker_Icon                { margin: 0 0 0 3px }
button.Zebra_DatePicker_Icon_Inside_Right   { margin: 0 3px 0 0 }
button.Zebra_DatePicker_Icon_Inside_Left    { margin: 0 0 0 3px }